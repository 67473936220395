import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"

import "../components/Content.css"

export const PrivacyPolicyPageTemplate = ({
  title,
  description,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container mx-auto pt-10 lg:pt-24 px-10 lg:px-32 pb-10 lg:pb-24">
        <div className="flex flex-row">
          <h2 className="font-semibold text-4xl tracking-wide text-gray-900 leading-snug">
            {title}
          </h2>
        </div>
        <div className="flex flex-row">
          <div className="font-light text-lg text-gray-500 mt-4 mb-10 lg:max-w-3xl md:max-w-md">
            {description}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="md:w-4/5">
            <PageContent className="privacy-policy mr-5" content={content} />
          </div>
        </div>
      </div>
    </>
  )
}

PrivacyPolicyPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PrivacyPolicyPage = ({ data, location }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout location={location}>
      <PrivacyPolicyPageTemplate
        {...frontmatter}
        contentComponent={HTMLContent}
        content={html}
      />
    </Layout>
  )
}

PrivacyPolicyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PrivacyPolicyPage

export const PrivacyPolicyPageQuery = graphql`
  query PrivacyPolicyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
